<template>
  <div id="app">
    <treeselect v-model="value" :multiple="true" :options="options" />
    <div @click="sub">submit</div>
  </div>
</template>

<script>



import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  // register the component
  components: { Treeselect },
  data() {
    return {
      // define the default value
      value: null,
      // define options
      options: [ {
        id: 'a',
        label: 'a',
        children: [ {
          id: 'aa',
          label: 'aa',
        }, {
          id: 'ab',
          label: 'ab',
        } ],
      }, {
        id: 'b',
        label: 'b',
      }, {
        id: 'c',
        label: 'c',
      } ],
    }

  },
  methods:{
    sub(){
      console.log(this.value)
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}
.warpbg {
  width: 100%;
  height: 500px;
}
.frame_baberrage {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 300px;
}
</style>
